'use strict';

Gri.module({
  name: 'select-alpha',
  ieVersion: null,
  $el: $('.select-alpha'),
  container: '.select-alpha',
  fn: function () {

    const $this = this.$el;
    let $filterSelect = $('.select-filter');

    $this.find('select').selectpicker({
      size: 4
    });

    $filterSelect.on('changed.bs.select', function () {
      let thisVal = $(this).val();
      let $elLink = $(this).parents('.tab-pane').find('.tab-pane-content a');
      $elLink.hide();
      $elLink.each(function () {
        if ($(this).attr('data-year') == thisVal) {
          $(this).show();
        }
      })
    });
    
  }
});
